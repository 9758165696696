import "whatwg-fetch";

const methods = ['GET', 'POST', 'PUT', 'PATCH', 'OPTIONS', 'DELETE'];

function formatUrl(path, config) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path;
  if (!Object.hasOwnProperty.call(config, 'apiPath')) {
    return adjustedPath;
  }

  return `${config.apiPath}${adjustedPath}`;
}

class ApiClient {
  constructor(config) {
    methods.forEach((method) => {
      this[method] = (path, { params, data, contentType, headers = {} } = {}) => {
        let defaultHeaders = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };

        if (contentType && contentType === 'form') {
          delete defaultHeaders['Content-Type'];
        }

        if (!data) {
          delete defaultHeaders['Content-Type'];
        }

        return fetch(formatUrl(path, config), {
          method: method,
          credentials: "include",
          headers: {...defaultHeaders, ...headers},
          body: contentType !== 'form' ? JSON.stringify(data): data,
          ...params,
        });
      };
    });
  }
}

export default ApiClient;