import React from 'react';
import { Redirect } from 'react-router-dom';
import loadable from "@loadable/component";

const Dashboard = loadable(() => import(/* webpackChunkName: "Dashboard" */'./Dashboard'));
const Login = loadable(() => import(/* webpackChunkName: "Login" */'./Login'));

const dashboardRenderFunc = (props, parentProps) => {
  return (
    props.isAuthenticated ?
    <Dashboard {...props} {...parentProps} />:
    !props.authLoading?
    <Redirect
      to={{
        pathname: "/login",
        state: { from: props.location }
      }}
    />: null
  );
};

const loginRenderFunc = (props, parentProps) => props.authLoading ? null:  <Login {...props} {...parentProps} />;

var indexRoutes = [
  { path: "/login", renderFunc: loginRenderFunc },
  { path: "/", renderFunc: dashboardRenderFunc },
];

export default indexRoutes;
