import "./reducer";
import Flag from "../../types/Flag";

export const newFlag = (title, message, actions, type) => {
  return (dispatch, getState) => {
    const flags = getState().FlagReducer.flags;
    const actionId = flags.length++;
    const flag = new Flag(title, message, actionId, actions, type);

    dispatch({
      type: "ADD_FLAG",
      flag,
    });

    return actionId;
  };
};

export const dismissFlag = (index) => {
  return {
    type: "DISMISS_FLAG",
    index,
  };
};