import short from 'short-uuid';

const translator = short();
export const randomName = () => {
  return translator.generate();
};

export const searchStringToObject = (search) => {
  if (typeof search === "string" && search.length) {
    let pairs = search.substring(1).split("&"),
      obj = {},
      pair,
      i;

    for (i in pairs) {
      if (pairs[i] === "") continue;

      pair = pairs[i].split("=");
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
  } else {
    return {};
  }
};

export const formatCurrency = (x) => x.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(".", ",");