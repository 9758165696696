import { parseResponse } from './common';
import "whatwg-fetch";

export const createAsyncClient = (store) => (method = "POST", options = {headers: {}}, path, body, callback) => {
  let API_PREFIX = "/api";
  if (process.env.NODE_ENV === "production") {
    API_PREFIX = "https://onboarding.wi-flix.com/api";
  }

  fetch(`${API_PREFIX}${path}`, {
    method,
    credentials: "include",
    body: body ? body instanceof FormData ? body: typeof body !== "string" ? JSON.stringify(body): body: null,
    ...options,
  }).then(response => {
    parseResponse(response, callback, store);
  }).catch(err => callback(err));
};

export default createAsyncClient();
