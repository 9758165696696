import React, {Component} from 'react';
import {withStyles, ThemeProvider, StylesProvider} from '@material-ui/styles';
import wrapDisplayName from 'recompose/wrapDisplayName';
import createContext from '../styles/createContext';
import Button from '@material-ui/core/Button';
import Error500 from "./ErrorBoundary/Error500";

// Apply some reset
export const baseStyles = theme => ({
  "@global": {
    "html": {
      fontFamily: "sans-serif",
      fontSize: "62.5%",
    },
    "html, body": {
      height: "100%",
      fontFamily: '"Roboto",-apple-system,".SFNSText-Regular","San Francisco",BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Helvetica,Arial,sans-serif',
    },
    "body": {
      maxWidth: "100%",
      margin: 0,
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: "normal",
      lineHeight: "16px",
      padding: 0,
      overflow: "auto",
      color: "rgba(0,0,0,0.87)",
      overflowX: "hidden!important",
    },
    "body, button": {
      WebkitFontSmoothing: "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
    },
    "*": {
      boxSizing: "border-box",
    },
    "#root, #app": {
      display: "table",
      tableLayout: "fixed",
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    "a": {
      color: "hsl(202,40%,55%)",
      background: "transparent",
      textDecoration: "none",
      // fontWeight: 500,
    },
    "button": {
      "-moz-appearance": "none",
      "-webkit-appearance": "none",
      "appearance": "none",
      "background": "none",
    },
    'fieldset': {
      minWidth: 0,
      border: 0,
    },
    'fieldset, legend': {
      margin: 0,
      padding: 0,
    },
    ":focus": {
      outline: 0,
    },
    "h1, h2, h3, h4, h5, h6": {
      border: 0,
      fontFamily: "inherit",
      fontStyle: "inherit",
      outline: 0,
      padding: 0,
      verticalAlign: "baseline",
    },
    "ul, ol, p, h1, h2, h3, h4, h5, h6": {
      textRendering: "auto",
    },
    "ul, ol": {
      padding: 0,
    },
    "p, h2, h3, h4, h5, h6": {
      wordWrap: "break-word",
    },
    "input": {
      lineHeight: "normal",
    },
    "h1, h2, h3": {
      marginTop: 20,
      marginBottom: 10,
    },
    "h3": {
      margin: "-7px 0 16px",
      bottom: -4,
      fontSize: 14,
      lineHeight: "16px",
    },
    ".atlaskit-portal": {
      zIndex: `${theme.zIndex.snackbar} !important`,
    },
  },
});

let AppWrapper = props => props.children;

AppWrapper = withStyles(baseStyles)(AppWrapper);

const context = createContext();

function withRoot(BaseComponent) {
  class Root extends Component {
    constructor(props, context) {
      super(props);
      this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
      console.log(errorInfo)
      this.setState({ error });
    }

    componentDidMount() {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles);
      }
    }

    render() {
      return (
        <StylesProvider jss={context.jss}>
          <ThemeProvider theme={context.theme}>
            <AppWrapper>
              {
                (() => {
                  if (this.state.error) {
                    // TODO: do a proper error reporting page
                    return (
                      <Error500 error={this.state.error}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            //report error to server
                          }}>Report feedback</Button>
                      </Error500>
                    );
                  } else {
                    return (
                      <BaseComponent {...this.props} />
                    );
                  }
                })()
              }
            </AppWrapper>
          </ThemeProvider>
        </StylesProvider>
      );
    }
  }

  if (typeof BaseComponent.getInitialProps === "function") {
    Root.getInitialProps = BaseComponent.getInitialProps;
  }

  if (process.env.NODE_ENV !== 'production') {
    Root.displayName = wrapDisplayName(BaseComponent, 'withRoot');
  }

  return Root;
}

export default withRoot;
