// @flow

import { create, SheetsRegistry } from 'jss';
import preset from 'jss-preset-default';
import { createGenerateClassName } from "@material-ui/styles";
import theme from "../theme";

const createGenerateId = () => {
  let counter = 0;

  return (rule, sheet) => `pp--${rule.key}-${counter++}`
};

export default function createContext() {
  // Configure JSS
  const jss = create(preset());
  jss.options.generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS: false,
    productionPrefix: 'pp',
  });
  jss.options.createGenerateId = createGenerateId;

  const sheetsManager = new Map();

  return {
    jss,
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager,
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
  };
}
