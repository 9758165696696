import { parseResponse } from "../utils/common";


export default function promiseMiddleware(client) {
  return ({ dispatch, getState }) => next => action => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }
    const { promise, type, skipRequest, ...rest } = action;

    if (!promise) return next(action);

    const SUCCESS = type;

    const REQUEST = `${type}_REQUEST`;
    const FAILURE = `${type}_FAILURE`;

    const actionPromise = promise(client);
    if (!skipRequest) {
      next({ ...rest, type: REQUEST, cancel: actionPromise.abort });
    }

    actionPromise
      .then((response) => {
        parseResponse(response, (error, body) => {
          if (error) {
            return next({ ...rest, error, type: FAILURE });
          }

          next({ ...rest, body, type: SUCCESS });
        });
      })
      .catch(error => {
        return next({ ...rest, error, type: FAILURE });
      });
    return actionPromise;
  };
}