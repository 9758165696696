import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import withRoot from '../withRoot';
import classnames from "classnames";

const styles = theme => ({
  root: {
    position: "absolute",
    pointerEvents: "none",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    "& > $pane$modal": {
      justifyContent: "center",
      alignItems: "center",
      background: "rgba(33,33,33,.4)",
      pointerEvents: "auto",
      position: "fixed",
      WebkitBackfaceVisibility: "hidden",
      backfaceVisibility: "hidden",
    },
    "& > $pane": {
      display: "flex",
      position: "absolute",
      zIndex: 11,
      pointerEvents: "none",
    },
    "& > $pane > *": {
      display: "flex",
      pointerEvents: "auto",
    },
  },
  pane: {},
  modal: {},
});


class CustomModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    promise: PropTypes.shape({
      resolve: PropTypes.func.isRequired,
      reject: PropTypes.func.isRequired,
    }).isRequired,
    contentProps: PropTypes.object,
  };

  static defaultProps = {
    contentProps: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: true,
    };
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  close = () => {
    const { onClose, promise } = this.props;
    this.closeModal();

    if (typeof onClose === 'function') {
      onClose();
      return promise.reject();
    }

    return promise.reject();
  };

  render() {
    const {classes, theme, ...props} = this.props;

    return (
      <Modal
        aria-labelledby={props.title}
        aria-describedby="modal"
        open={this.state.isModalOpen}
        style={{overflow: "auto", zIndex: theme.zIndex.appBar + 1}}
        className={classes.root}>
        <div
          style={{left: 0, top: 0, bottom: 0, right: 0}}
          className={classnames(classes.pane, classes.modal)}>
          {
            React.cloneElement(this.props.children, {
              promise: this.props.promise,
              closeModal: this.closeModal,
              ...this.props.contentProps,
            })
          }
        </div>
      </Modal>
    );
  }
}

export default withRoot(withStyles(styles, {withTheme: true})(CustomModal));