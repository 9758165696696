import React, {Component} from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '../Typography/Primary';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import withRoot from '../withRoot';

const styles = theme => ({
  root: {
    width: '100%',
  },
  appBar: {
    boxShadow: "none",
    marginBottom: 0,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  button: {
    "& svg": {
      fontSize: 24,
      color: "#FFFFFF",
    },
  },
  paper: {
    position: 'absolute',
    minWidth: 540,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    top: "30%",
    left: "50%",
    transform: `translate(-30%, -50%)`,
  },
  title: {
    color: "#FFFFFF",
    fontWeight: 500,
    marginLeft: 16,
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
  },
  toolbar: {
    minHeight: 52,
  },
  spacer: {
    flex: 1,
  },
  icon: {
    width: 17,
    height: 17,
  },
});


class CustomModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    title: PropTypes.string,
    promise: PropTypes.shape({
      resolve: PropTypes.func.isRequired,
      reject: PropTypes.func.isRequired,
    }).isRequired,
    contentProps: PropTypes.object,
  };

  static defaultProps = {
    contentProps: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: true,
    };
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  close = () => {
    const { onClose, promise } = this.props;
    this.closeModal();

    if (typeof onClose === 'function') {
      onClose();
      return promise.reject();
    }

    return promise.reject();
  };

  render() {
    const {classes} = this.props;

    return (
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal"
        open={this.state.isModalOpen}
        className={classes.root}>
        <div className={classes.paper}>
          <AppBar classes={{root: classes.appBar}} position="static" color="primary">
            <Toolbar disableGutters classes={{regular: classes.toolbar}}>
              {
                this.props.title &&
                <Typography classes={{primaryText: classes.title}}>
                  {this.props.title}
                </Typography>
              }
              <div className={classes.spacer}></div>
              <IconButton onClick={this.close} className={classes.button} aria-label="Close">
                <CancelIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
            {
              React.cloneElement(this.props.children, {
                promise: this.props.promise,
                closeModal: this.closeModal,
                ...this.props.contentProps,
              })
            }
          </main>
        </div>
      </Modal>
    );
  }
}

export default withRoot(withStyles(styles)(CustomModal));