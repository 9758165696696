import "typeface-roboto";
import React from 'react';
import { render } from 'react-dom';
import App from './pages';
import createStore from './store/create';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "react-redux";
import * as serviceWorker from './serviceWorker';
import ApiClient from "./utils/ApiClient";
import { createAsyncClient } from "./utils/async";
import config from './firebase-config.json';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/messaging';
import 'firebase/storage';
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

const rootElement = document.querySelector('#root');
const BACKEND_URL = "https://fl-api.wi-flix.com";

export const firebaseApp = firebase.initializeApp(config);

const client = new ApiClient({
  apiPath: BACKEND_URL,
});

const rrfConfig = {
  firebase: firebaseApp,
  config: {
    userProfile: 'users',
    attachAuthIsReady: true,
    updateProfileOnLogin: true,
  },
};

// TODO: make store promise middleware use async client
export const store = createStore(client, {});
const async = createAsyncClient(store);

window.onload = () => {
  if (rootElement) {
    if (process.env.NODE_ENV === "production") {
      // set up an error reporting tool
    }

    render(
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfConfig} dispatch={store.dispatch}>
          <Router>
            <App async={async} />
          </Router>
        </ReactReduxFirebaseProvider>
      </Provider>,
      rootElement
    );
  }
};

// TODO: change this later to support service workers
serviceWorker.unregister();