import React from "react";
import { randomName } from "../utils/names";
import Info from "@material-ui/icons/Info";
import Success from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
import Error from "@material-ui/icons/Error";


const icons = {
  info: <Info fontSize="large" />,
  success: <Success fontSize="large" />,
  warning: <Warning fontSize="large" />,
  error: <Error fontSize="large" />,
};

const getIcon = (type) => {
  return icons[type] || Info;
};

class Flag {
  created = Date.now();
  message = "";
  id = 0;
  type = "info";
  icon = null;
  key = "";
  title = "";
  actions = [];

  constructor(title, message, index, actions = [], type = "info") {
    this.id = index;
    this.title = title;
    this.message = message;
    this.icon = getIcon(type);//set icon base on type
    this.key = randomName();
    this.created = Date.now();
    this.actions = actions;
    this.type = type;
  }

  toJSON() {
    return {
      created: this.created,
      message: this.message,
      id: this.id,
      type: this.type,
      icon: this.icon,
      key: this.key,
      title: this.title,
      actions: this.actions,
    };
  }
}

export default Flag;