import { newFlag, dismissFlag } from "../components/Flags/actions";

class FlagManager {
  dispatch = () => {};
  store = null;

  static Actions = {
    DISMISS: {
      content: "Dismiss",
      onClick: (id, dispatch) => {
        dispatch(dismissFlag(id));
      },
    },
    OK: {
      content: "Looks good!",
      onClick: (id, dispatch) => {
        dispatch(dismissFlag(id));
      },
    },
  };

  constructor(store) {
    this.dispatch = store.dispatch;
    this.store = store;
  }

  addFlag(title, message, actions, type, asyncDispatch) {
    const dispatch = asyncDispatch || this.dispatch;

    const modifiedActions = actions.map(action => (
      Object.assign({}, action, {
        onClick: (id) => {
          action.onClick.apply(null, [id, dispatch]);
        }
      })
    ));

    dispatch(newFlag(title, message, modifiedActions, type));
  }

  info(title, message, actions = [], asyncDispatch) {
    this.addFlag(title, message, actions, "info", asyncDispatch);
  }

  success(title, message, actions = [], asyncDispatch) {
    this.addFlag(title, message, actions, "success", asyncDispatch);
  }

  error(title, message, actions = [], asyncDispatch) {
    this.addFlag(title, message, actions, "error", asyncDispatch);
  }

  warn(title, message, actions = [], asyncDispatch) {
    this.addFlag(title, message, actions, "warning", asyncDispatch);
  }
}

export default FlagManager;