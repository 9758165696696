import React from 'react';
import Flag, { FlagGroup } from '@atlaskit/flag';
import { dismissFlag } from "./actions";
import { useSelector, useDispatch } from 'react-redux';

function Flags() {
  const dispatch = useDispatch();
  const { flags } = useSelector(state => ({flags: state.FlagReducer.flags}));

  return (
    <div>
      <FlagGroup onDismissed={(index) => dispatch(dismissFlag(index))}>
          {
            flags.map(flag => {
              return (
                <Flag
                  {...flag}
                  description={flag.message}
                  appearance={flag.type}
                  icon={flag.icon}
                  actions={flag.actions.map(entry => Object.assign({}, entry, {
                    onClick: (evt) => {
                      evt.preventDefault();
                      entry.onClick.call(null, flag.id);
                    },
                  }))}
                />
              );
            })
          }
      </FlagGroup>
    </div>
  );
}

export default Flags;