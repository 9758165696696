import reducerRegistry from "../../store/registry";

const defaultState = {
  flags: []
};

function FlagReducer(state=defaultState, action) {
  switch (action.type) {
    case "ADD_FLAG":
      return {
        flags: [action.flag.toJSON(), ...state.flags.slice(0, state.flags.length-1)],
      };
    case "DISMISS_FLAG":
      return {
        ...state,
        flags: state.flags.filter(flag => flag.id !== action.index)
      };
    default:
      return state;
  }
}

reducerRegistry.register("FlagReducer", FlagReducer);
